import { Logo } from 'components/atoms/logo';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegEnvelope } from 'react-icons/fa';
import { IoSettingsOutline } from 'react-icons/io5';
import { LuLayoutDashboard } from 'react-icons/lu';
import {
  MdDarkMode,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdLightMode,
} from 'react-icons/md';
import { PiSuitcase } from 'react-icons/pi';
import { TbArrowsRightLeft, TbLogout } from 'react-icons/tb';

import { authAsync } from 'core/store/modules/auth';
import { Theme, configAsync, configSelectors } from 'core/store/modules/config';
import { Dispatch, RootState } from 'core/store/store.types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './style.css';

interface OwnProps {
  activeRef: string;
}
interface StateProps {
  isCollapsed: boolean;
  theme: Theme;
}
interface DispatchProps {
  logout: () => void;
  handleCollapse: () => void;
  handleChangeTheme: (theme: Theme) => void;
}
export type Props = OwnProps & StateProps & DispatchProps;

const Sidebar: FunctionComponent<Props> = ({
  activeRef,
  logout,
  isCollapsed,
  handleCollapse,
  theme,
  handleChangeTheme,
}) => {
  const { t } = useTranslation();

  const sidebarItems = [
    {
      name: t('sidebar_dashboard'),
      href: '/',
      icon: LuLayoutDashboard,
    },
    {
      name: t('sidebar_tours'),
      href: '/tours',
      icon: TbArrowsRightLeft,
    },
    {
      name: t('sidebar_requests'),
      href: '/reservation-requests',
      icon: PiSuitcase,
    },
    {
      name: t('sidebar_chat'),
      href: '/chat',
      icon: FaRegEnvelope,
    },
  ];

  const isActive = (href: string) => {
    return activeRef === href;
  };

  const navigate = useNavigate();

  return (
    <div className="h-full relative block">
      <button
        className="btn bg-light-bg_component dark:bg-dark-bg_component border-2 border-light-active dark:border-white text-light-active dark:text-white"
        onClick={handleCollapse}
      >
        {isCollapsed ? <MdKeyboardArrowRight /> : <MdKeyboardArrowLeft />}
      </button>
      <aside
        className="sidebar bg-light-bg_component dark:bg-dark-bg_component border-r-2 border-light-active dark:border-white"
        data-collapse={isCollapsed}
      >
        <div>
          <div className="sidebar__top border-b-2 border-light-active text-light-active dark:text-white dark:border-white">
            <Logo />
            <p className="sidebar__logo-name">WanderWish</p>
          </div>
          <ul className="sidebar__list">
            {sidebarItems.map(({ name, href, icon: Icon }) => {
              return (
                <li className="sidebar__item" key={name}>
                  <div
                    className={`sidebar__link cursor-pointer  ${
                      isActive(href)
                        ? 'bg-light-active text-white dark:bg-dark-active'
                        : 'text-light-active dark:text-white'
                    }`}
                    onClick={() => navigate(href)}
                  >
                    <span className="sidebar__icon">
                      <Icon />
                    </span>
                    <span className="sidebar__name">{name}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <ul>
            <li className="sidebar__item" key="settings">
              <div
                className={`sidebar__link cursor-pointer ${
                  isActive('/settings')
                    ? 'bg-light-active text-white dark:bg-dark-active'
                    : 'text-light-active dark:text-white'
                }`}
                onClick={() => navigate('/settings')}
              >
                <span className="sidebar__icon">
                  <IoSettingsOutline />
                </span>
                <p className="sidebar__name">{t('sidebar_settings')}</p>
              </div>
            </li>
            <li className="sidebar__item" key="logout">
              <div
                className="sidebar__link text-light-active dark:text-white cursor-pointer"
                onClick={logout}
              >
                <span className="sidebar__icon">
                  <TbLogout />
                </span>
                <p className="sidebar__name">{t('common_logout')}</p>
              </div>
            </li>
            <li className="sidebar__item " key="lightmode">
              {!isCollapsed && (
                <div className="sidebar__link text-light-active dark:text-white cursor-pointer justify-between w-full">
                  <label className="flex items-center justify-between relative w-full cursor-pointer select-none">
                    <p className="sidebar__name !ml-0 mr-2">
                      {t('common_light_mode')}
                    </p>
                    <input
                      type="checkbox"
                      checked={theme === Theme.light}
                      onChange={() =>
                        handleChangeTheme(
                          theme === Theme.light ? Theme.dark : Theme.light,
                        )
                      }
                      className="appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none bg-dark-active checked:bg-light-active"
                    />
                    <span className="w-6 h-6 right-7 absolute rounded-full transform transition-transform bg-gray-200 border border-gray-500" />
                  </label>
                </div>
              )}
              {isCollapsed && (
                <div className="sidebar__link text-light-active dark:text-white cursor-pointer">
                  <span className="sidebar__icon">
                    {theme === Theme.light ? (
                      <MdDarkMode
                        onClick={() => handleChangeTheme(Theme.dark)}
                      />
                    ) : (
                      <MdLightMode
                        onClick={() => handleChangeTheme(Theme.light)}
                      />
                    )}
                    <p className="sidebar__name">Theme</p>
                  </span>
                </div>
              )}
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    isCollapsed: configSelectors.getCollapsed(state),
    theme: configSelectors.getTheme(state),
  }),
  (dispatch: Dispatch) => ({
    logout: () => dispatch(authAsync.logout()),
    handleCollapse: () => dispatch(configAsync.handleCollapse()),
    handleChangeTheme: (theme: Theme) =>
      dispatch(configAsync.handleChangeTheme(theme)),
  }),
)(Sidebar);
