import { THEME_STORAGE_KEY } from './storage.theme.const';

let cachedTheme: string | null;

export const getTheme = () => {
  if (cachedTheme) {
    return cachedTheme;
  }
  try {
    cachedTheme = localStorage.getItem(THEME_STORAGE_KEY);
    return cachedTheme;
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export const setTheme = (newTheme: string) => {
  try {
    localStorage.setItem(THEME_STORAGE_KEY, newTheme);
    cachedTheme = newTheme;
  } catch (e) {
    console.warn(e);
  }
};

export const clearTheme = () => {
  try {
    localStorage.removeItem(THEME_STORAGE_KEY);
    cachedTheme = null;
  } catch (e) {
    console.warn(e);
  }
};
