import TourTableDate from 'components/molecules/tour-table-date';
import { TourDatesType } from 'mocks/tours-date';
import { Tour } from 'mocks/tours-list';
import { FunctionComponent } from 'react';

export interface Props {
  selectedTour: Tour;
  toursDate: TourDatesType[];
}

const TourInfo: FunctionComponent<Props> = ({ selectedTour, toursDate }) => {
  return (
    <div className="flex w-full mt-2 h-full overflow-hidden">
      <div className="w-1/3 h-7/8">SX</div>
      <TourTableDate toursDate={toursDate} />
    </div>
  );
};

TourInfo.displayName = 'TourInfo';

export default TourInfo;
