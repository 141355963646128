import { Input } from 'components/atoms/input';
import { ThemeSwitcher } from 'components/atoms/theme-switcher';
import { SigninDto } from 'core/mutations/signin';
import { authAsync, authSelectors } from 'core/store/modules/auth';
import { Theme, configAsync, configSelectors } from 'core/store/modules/config';
import { Dispatch, RootState } from 'core/store/store.types';
import { VariableData } from 'core/types/VariableData';
import { EyeFilledIcon } from 'icons/eye-filled-icon';
import { EyeSlashFilledIcon } from 'icons/eye-slash-filled-icon';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface OwnProps {}
interface StateProps {
  isAuthLoading: boolean;
  authError?: string;
  token?: string;
  theme: Theme;
}
interface DispatchProps {
  login: (variables: VariableData<SigninDto>) => void;
  authErrorReset: () => void;
  handleThemeChange: (theme: Theme) => void;
}
export type Props = OwnProps & StateProps & DispatchProps;

const Login: FunctionComponent<Props> = ({
  login,
  isAuthLoading,
  authErrorReset,
  token,
  authError,
  theme,
  handleThemeChange,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  const isDisabled = email === '' || password === '';

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleLogin = async () => {
    const variables = {
      data: {
        email,
        password,
      },
    };
    login(variables);
  };

  return (
    <>
      {!token && (
        <div className="h-full flex flex-col">
          <nav className="flex z-40 w-full h-16 items-center justify-center">
            <header className="z-40 flex px-6 gap-4 w-full flex-row relative flex-nowrap items-center justify-between h-16 max-w-[1280px]">
              <p className="font-bold text-base dark:text-white">WANDERWISH</p>
              <div className="flex">
                <div className="mr-3">
                  <ThemeSwitcher
                    theme={theme}
                    handleChangeTheme={handleThemeChange}
                  />
                </div>
                <div className="cursor-pointer dark:text-white">
                  {i18n.language.includes('en') && (
                    <p onClick={() => i18n.changeLanguage('it')}>IT</p>
                  )}
                  {i18n.language.includes('it') && (
                    <p onClick={() => i18n.changeLanguage('en')}>EN</p>
                  )}
                </div>
              </div>
            </header>
          </nav>
          <section className="flex-grow container mx-auto flex flex-col items-center justify-center gap-4">
            <div className="flex-grow w-full flex flex-col">
              <div className="flex flex-grow p-5 px-6">
                {/* Colonna sinistra */}
                <div
                  className="lg:w-1/2 w-full md:p-10 h-full"
                  style={{ zIndex: 2 }}
                >
                  <div className="flex flex-col justify-center h-full">
                    <h1 className="font-figtree text-4xl font-bold text-center dark:text-white mb-6">
                      {t('common_login')}
                    </h1>
                    <Input
                      id="email"
                      label={t('common_email')}
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                      placeholder={t('input_email_placeholder')}
                    />
                    <div className="h-0 mb-6"></div>
                    <Input
                      id="password"
                      label={t('common_password')}
                      value={password}
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                      placeholder={t('input_password_placeholder')}
                      type={isVisible ? 'text' : 'password'}
                      endContent={
                        <button
                          className="focus:outline-none dark:text-white"
                          type="button"
                          onClick={toggleVisibility}
                        >
                          {isVisible ? (
                            <EyeSlashFilledIcon className="text-xl text-default-400 pointer-events-none" />
                          ) : (
                            <EyeFilledIcon className="text-xl text-default-400 pointer-events-none" />
                          )}
                        </button>
                      }
                    />
                    <button
                      className={`mt-6 bg-light-bg_button dark:bg-dark-bg_button p-3 rounded-xl text-white dark:text-inherit ${
                        isDisabled && 'opacity-50'
                      }`}
                      disabled={isDisabled}
                      onClick={handleLogin}
                    >
                      {t('common_login')}
                    </button>
                  </div>
                </div>

                {/* Colonna destra */}
                <div
                  className="h-full w-1/2 p-20 flex flex-col justify-center items-center hidden md:block bg-cover bg-center rounded-2xl relative"
                  style={{ backgroundImage: 'url(/images/signin.jpg)' }}
                ></div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    isAuthLoading: authSelectors.getLoginLoading(state),
    authError: authSelectors.getLoginError(state),
    token: authSelectors.getToken(state),
    theme: configSelectors.getTheme(state),
  }),
  (dispatch: Dispatch) => ({
    login: (variables: VariableData<SigninDto>) =>
      dispatch(authAsync.login(variables)),
    authErrorReset: () => dispatch(authAsync.authErrorReset()),
    handleThemeChange: (theme: Theme) =>
      dispatch(configAsync.handleChangeTheme(theme)),
  }),
)(Login);
