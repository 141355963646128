import { Dispatch } from 'core/store/store.types';
import * as actions from './config.actions';
import { Theme } from './config.types';
import { setTheme } from 'core/storage';

export const handleCollapse = () => async (dispatch: Dispatch) => {
  dispatch(actions.setCollapsed());
};

export const handleChangeTheme =
  (theme: Theme) => async (dispatch: Dispatch) => {
    setTheme(theme);
    dispatch(actions.setTheme(theme));
  };
