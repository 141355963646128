import { Route, Routes } from 'react-router-dom';

import App from './App';
import { LoginPage } from 'pages/login';
import { DashboardPage } from 'pages/dashboard';
import { ToursPage } from 'pages/tours';
import { RequestsPage } from 'pages/requests';
import { ChatPage } from 'pages/chat';
import { SettingsPage } from 'pages/settings';

const routes = (
  <App>
    <Routes>
      <Route path="/login" Component={LoginPage} />
      <Route path="/" Component={DashboardPage} />
      <Route path="/tours" Component={ToursPage} />
      <Route path="/reservation-requests" Component={RequestsPage} />
      <Route path="/chat" Component={ChatPage} />
      <Route path="/settings" Component={SettingsPage} />
    </Routes>
  </App>
);

export default routes;
