import { FunctionComponent } from 'react';
import { MdOutlineSearch } from 'react-icons/md';

export interface Props {
  placeholder: string;
  searchValue: string;
  setSearchValue: (value: string) => void;
}

const SearchInput: FunctionComponent<Props> = ({
  placeholder,
  searchValue,
  setSearchValue,
}) => (
  <div className="w-full p-2.5 rounded-xl border border-light-active dark:border-white bg-light-bg flex items-center dark:bg-white dark:bg-opacity-10 h-fit">
    <div className="flex items-center">
      <MdOutlineSearch className="text-light-active dark:text-white" />
    </div>

    <input
      type="text"
      value={searchValue}
      onChange={event => setSearchValue(event.target.value)}
      placeholder={placeholder}
      className="text-light-active dark:text-white dark:placeholder:text-white placeholder:text-light-active text-sm focus:outline-none border-0 bg-light-bg ml-1 dark:bg-transparent"
    />
  </div>
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
