import dayjs from 'dayjs';

export const getDateString = (jsDate: Date) => {
  const date = dayjs(jsDate);
  if (isToday(jsDate)) {
    return date.format('HH:mm');
  } else if (isYesterday(jsDate)) {
    return 'yesterday';
  } else {
    return date.format('DD/MM/YYYY');
  }
};

const isToday = (jsDate: Date) => {
  const date = dayjs(dayjs(jsDate).format('YYYY-MM-DD'));
  const today = dayjs(dayjs().format('YYYY-MM-DD'));
  return date.isSame(today);
};

const isYesterday = (jsDate: Date) => {
  const date = dayjs(dayjs(jsDate).format('YYYY-MM-DD'));
  const today = dayjs(dayjs().format('YYYY-MM-DD'));
  return date.isSame(today.subtract(1, 'day'));
};
