import { Tour } from 'mocks/tours-list';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSettingsOutline } from 'react-icons/io5';

export interface Props {
  selectedTour?: Tour;
  toursList: Tour[];
  setSelectedTour: (tour?: Tour) => void;
}

const HEADERS_TAB = [
  {
    id: 1,
    label: 'tours_tour',
  },
  {
    id: 2,
    label: 'tours_description',
  },
  {
    id: 3,
    label: 'tours_states',
  },
  {
    id: 4,
    label: 'tours_days',
  },
  {
    id: 5,
    label: '',
  },
];

const ToursTable: FunctionComponent<Props> = ({
  selectedTour,
  toursList,
  setSelectedTour,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mt-2 h-full overflow-y-auto">
      <table className="w-full max-h-full">
        <thead className="sticky top-0">
          <tr className="bg-light-active dark:bg-dark-active text-white">
            {HEADERS_TAB.map(tab => (
              <th
                className="h-16 px-2 text-left text-xs font-semibold"
                key={tab.id}
              >
                {t(tab.label).toUpperCase()}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="dark:text-white">
          <tr className="h-2" />
          {toursList.map(row => (
            <tr
              className={`cursor-pointer mx-2 text-sm font-normal ${
                selectedTour?.id?.toString() === row.id.toString()
                  ? 'bg-light-active dark:bg-dark-active text:white'
                  : ''
              }`}
              key={row.id}
              onClick={() => setSelectedTour(row)}
            >
              <td
                className={`h-16 px-2 ${
                  selectedTour?.id?.toString() === row.id.toString()
                    ? 'rounded-l-xl text-white'
                    : ''
                }`}
              >
                <p className="line-clamp-2">{row.tour}</p>
              </td>
              <td
                className={`h-16 px-2 ${
                  selectedTour?.id?.toString() === row.id.toString()
                    ? 'text-white'
                    : ''
                }`}
              >
                <p className="line-clamp-2">{row.description}</p>
              </td>
              <td
                className={`h-16 px-2 ${
                  selectedTour?.id?.toString() === row.id.toString()
                    ? 'text-white'
                    : ''
                }`}
              >
                <p className="line-clamp-2">{row.states}</p>
              </td>
              <td
                className={`h-16 px-2 ${
                  selectedTour?.id?.toString() === row.id.toString()
                    ? 'text-white'
                    : ''
                }`}
              >
                <p className="line-clamp-2">{row.days}</p>
              </td>
              <td
                className={`h-16 px-2 ${
                  selectedTour?.id?.toString() === row.id.toString()
                    ? 'rounded-r-xl text-white'
                    : ''
                }`}
              >
                <button
                  onClick={(evt: React.MouseEvent) => {
                    evt.stopPropagation();
                    console.log('info');
                  }}
                >
                  <IoSettingsOutline />
                </button>
              </td>
            </tr>
          ))}
          <tr className="h-2" />
        </tbody>
      </table>
    </div>
  );
};

ToursTable.displayName = 'ToursTable';

export default ToursTable;
