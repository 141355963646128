import { ChatMessage as ChatMessageType } from 'mocks/chat-messages';
import { FunctionComponent } from 'react';
import { BiCheck, BiCheckDouble } from 'react-icons/bi';
import dayjs from 'dayjs';

export interface Props {
  message: ChatMessageType;
}

const ChatMessage: FunctionComponent<Props> = ({ message }) => (
  <div
    key={message.id}
    className={`flex w-full items-center py-1 ${
      message.received ? '' : 'justify-start flex-row-reverse text-white '
    }`}
  >
    <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
      <span className="font-medium text-light-active dark:text-white text-sm">
        TT
      </span>
    </div>
    <div className="flex flex-col">
      <div
        className={`rounded-full ${
          message.received
            ? 'bg-gray-200 dark:bg-gray-600 ml-1 text-light-active dark:text-white'
            : 'bg-light-active dark:bg-dark-active mr-1'
        } p-3 text-sm`}
      >
        {message.message}
      </div>
      <div
        className={`flex items-center text-light-active dark:text-white ${
          message.received ? '' : 'justify-end'
        }`}
      >
        {message.dateViewed && !message.received && <BiCheckDouble />}
        {!message.dateViewed && !message.received && <BiCheck />}
        <p className="font-medium text-sm ml-1">
          {dayjs(message.dateSent).format('HH:mm')}
        </p>
      </div>
    </div>
  </div>
);

ChatMessage.displayName = 'ChatMessage';

export default ChatMessage;
