import axios, { AxiosRequestConfig } from 'axios';
import { WANDERWISH_URL } from 'core/config/env';
import { handleErrorGraphQL } from '../interceptors/handle-error-graphql';

const axiosConfig: AxiosRequestConfig = {
  baseURL: `${WANDERWISH_URL}/graphql`,
};

const wanderwishHttpClient = axios.create(axiosConfig);

wanderwishHttpClient.interceptors.response.use(handleErrorGraphQL);

export { wanderwishHttpClient };
