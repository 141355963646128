import { FunctionComponent } from 'react';
import { GrEmoji } from 'react-icons/gr';
import { IoSend } from 'react-icons/io5';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Theme } from 'core/store/modules/config';

export interface Props {
  text: string;
  setText: (text: string) => void;
  showEmoji: boolean;
  setShowEmoji: (value: boolean) => void;
  theme: Theme;
}

const ChatInput: FunctionComponent<Props> = ({
  text,
  setText,
  showEmoji,
  setShowEmoji,
  theme,
}) => {
  return (
    <div className="w-full bg-gray-300 dark:bg-gray-700 rounded-t-xl">
      <textarea
        className="rounded-t-xl bg-gray-100 dark:bg-gray-600 w-full focus:outline-none p-2 text-light-active dark:text-white"
        rows={4}
        style={{ resize: 'none' }}
        value={text}
        onChange={event => setText(event.target.value)}
      />
      <div className="flex pl-1 pr-2 py-2 justify-end text-light-active dark:text-white">
        {showEmoji && (
          <div className="absolute bottom-24 z-50">
            <Picker
              data={data}
              onEmojiSelect={(data: {
                id: string;
                name: string;
                native: string;
                shortcodes: string;
                unified: string;
                keywords: string[];
              }) => {
                setText(text + data.native);
                setShowEmoji(false);
              }}
              theme={theme}
              onClickOutside={() => setShowEmoji(false)}
            />
          </div>
        )}
        <GrEmoji
          onClick={evt => {
            evt.stopPropagation();
            setShowEmoji(!showEmoji);
          }}
        />
        <div className="ml-3">
          <IoSend onClick={() => console.log('SEND')} />
        </div>
      </div>
    </div>
  );
};

ChatInput.displayName = 'ChatInput';

export default ChatInput;
