export interface ChatMessage {
  id: number;
  message: string;
  received: boolean;
  dateSent: Date;
  dateViewed: Date;
}

export const CASUAL_MESSAGES: ChatMessage[] = [
  {
    id: 1,
    message: "Hey there! How's it going?",
    received: true,
    dateSent: new Date('2023-12-05T09:00:00'),
    dateViewed: new Date('2023-10-17T09:30:00'),
  },
  {
    id: 2,
    message: "Hi! I'm doing well, thanks. How about you?",
    received: false,
    dateSent: new Date('2023-12-04T09:15:00'),
    dateViewed: new Date('2023-10-17T09:45:00'),
  },
  {
    id: 3,
    message: "I'm having a busy day today!",
    received: true,
    dateSent: new Date('2023-10-17T10:00:00'),
    dateViewed: new Date('2023-10-17T10:30:00'),
  },
  {
    id: 4,
    message: "Oh, I can imagine. What's keeping you busy?",
    received: false,
    dateSent: new Date('2023-10-17T10:15:00'),
    dateViewed: new Date('2023-10-17T10:45:00'),
  },
  {
    id: 5,
    message: "Work, errands, and some personal projects. It's a lot to handle!",
    received: true,
    dateSent: new Date('2023-10-17T11:00:00'),
    dateViewed: new Date('2023-10-17T11:30:00'),
  },
  {
    id: 6,
    message: 'Sounds like a full day! Make sure to take breaks too.',
    received: false,
    dateSent: new Date('2023-10-17T11:15:00'),
    dateViewed: new Date('2023-10-17T11:45:00'),
  },
  {
    id: 7,
    message: "You're right; I'll make time for that. How's your day going?",
    received: true,
    dateSent: new Date('2023-10-17T12:00:00'),
    dateViewed: new Date('2023-10-17T12:30:00'),
  },
  {
    id: 8,
    message: "Not too hectic. I'm enjoying some downtime at the moment.",
    received: false,
    dateSent: new Date('2023-10-17T12:15:00'),
    dateViewed: new Date('2023-10-17T12:45:00'),
  },
  {
    id: 9,
    message: 'That sounds nice. I wish I had some downtime too!',
    received: true,
    dateSent: new Date('2023-10-17T13:00:00'),
    dateViewed: new Date('2023-10-17T13:30:00'),
  },
  {
    id: 10,
    message: 'Hang in there; your break is just around the corner!',
    received: false,
    dateSent: new Date('2023-10-17T13:15:00'),
    dateViewed: new Date('2023-10-17T13:45:00'),
  },
  {
    id: 11,
    message: 'I know, I know. Thanks for the encouragement!',
    received: true,
    dateSent: new Date('2023-10-17T14:00:00'),
    dateViewed: new Date('2023-10-17T14:30:00'),
  },
  {
    id: 12,
    message: "You're welcome! Anything exciting happening today?",
    received: false,
    dateSent: new Date('2023-10-17T14:15:00'),
    dateViewed: new Date('2023-10-17T14:45:00'),
  },
  {
    id: 13,
    message: 'Not really, just the usual stuff. How about you?',
    received: true,
    dateSent: new Date('2023-10-17T15:00:00'),
    dateViewed: new Date('2023-10-17T15:30:00'),
  },
  {
    id: 14,
    message: 'Same here. We should plan something fun soon!',
    received: false,
    dateSent: new Date('2023-10-17T15:15:00'),
    dateViewed: new Date('2023-10-17T15:45:00'),
  },
  {
    id: 15,
    message: "Definitely! Let's catch up over the weekend.",
    received: true,
    dateSent: new Date('2023-10-17T16:00:00'),
    dateViewed: new Date('2023-10-17T16:30:00'),
  },
  {
    id: 16,
    message: "Sounds like a plan. I'm looking forward to it!",
    received: false,
    dateSent: new Date('2023-10-17T16:15:00'),
    dateViewed: new Date('2023-10-17T16:45:00'),
  },
  {
    id: 17,
    message: 'What do you want to do this weekend?',
    received: true,
    dateSent: new Date('2023-10-17T17:00:00'),
    dateViewed: new Date('2023-10-17T17:30:00'),
  },
  {
    id: 18,
    message: 'How about going for a hike and then having a picnic?',
    received: false,
    dateSent: new Date('2023-10-17T17:15:00'),
    dateViewed: new Date('2023-10-17T17:45:00'),
  },
  {
    id: 19,
    message: "That sounds perfect! Let's do it.",
    received: true,
    dateSent: new Date('2023-10-17T18:00:00'),
    dateViewed: new Date('2023-10-17T18:30:00'),
  },
  {
    id: 20,
    message: "Great! We'll have a fantastic weekend.",
    received: false,
    dateSent: new Date('2023-10-17T18:15:00'),
    dateViewed: new Date('2023-10-17T18:45:00'),
  },
  {
    id: 21,
    message: "I can't wait! It's going to be so much fun.",
    received: true,
    dateSent: new Date('2023-10-17T19:00:00'),
    dateViewed: new Date('2023-10-17T19:30:00'),
  },
  {
    id: 23,
    message: 'By the way, do you have any dietary preferences for the picnic?',
    received: true,
    dateSent: new Date('2023-10-17T20:00:00'),
    dateViewed: new Date('2023-10-17T20:30:00'),
  },
  {
    id: 24,
    message: "I'm a vegetarian, so I'll bring some veggie options.",
    received: false,
    dateSent: new Date('2023-10-17T20:15:00'),
    dateViewed: new Date('2023-10-17T20:45:00'),
  },
  {
    id: 25,
    message: "Perfect! I'll make sure to have some vegetarian goodies too.",
    received: true,
    dateSent: new Date('2023-10-17T21:00:00'),
    dateViewed: new Date('2023-10-17T21:30:00'),
  },
  {
    id: 26,
    message: "Perfect! I'll make sure to have some vegetarian goodies too. Bye",
    received: true,
    dateSent: new Date('2023-10-16T21:00:00'),
    dateViewed: new Date('2023-10-16T21:30:00'),
  },
];
