export type Tour = {
  id: number;
  tour: string;
  description: string;
  states: string;
  days: number;
};

export const TOURS_LIST = [
  {
    id: 1,
    tour: 'Viaggio alle Cascate',
    description: 'Esplora le maestose cascate in questa avventura emozionante.',
    states: 'California, Nevada, Hawaii, Utah, Alabama',
    days: 3,
  },
  {
    id: 2,
    tour: 'Tour Culturale a Roma',
    description:
      "Scopri la storia e l'arte della Città Eterna in un tour indimenticabile.",
    states: 'Italia',
    days: 5,
  },
  {
    id: 3,
    tour: 'Avventura nei Monti Rockies',
    description:
      "Un'esperienza di trekking epica tra le vette spettacolari delle Montagne Rocciose.",
    states: 'Canada',
    days: 7,
  },
  {
    id: 4,
    tour: 'Spiagge Paradisiache in Thailandia',
    description:
      'Rilassati sulle spiagge di sabbia bianca e scopri la cultura affascinante della Thailandia. Alla ricerca di Dario e Daniela spersi nel continente asiatico e prima o poi magari verranno ritrovati a lavorare in una risaia.',
    states: 'Thailandia',
    days: 10,
  },
  {
    id: 5,
    tour: 'Esplorazione della Grande Muraglia',
    description:
      'Cammina lungo la Grande Muraglia e immergiti nella storia millenaria della Cina.',
    states: 'Cina',
    days: 4,
  },
  {
    id: 6,
    tour: 'Viaggio alle Cascate',
    description: 'Esplora le maestose cascate in questa avventura emozionante.',
    states: 'California, Nevada, Hawaii, Utah, Alabama',
    days: 3,
  },
  {
    id: 7,
    tour: 'Tour Culturale a Roma',
    description:
      "Scopri la storia e l'arte della Città Eterna in un tour indimenticabile.",
    states: 'Italia',
    days: 5,
  },
  {
    id: 8,
    tour: 'Avventura nei Monti Rockies',
    description:
      "Un'esperienza di trekking epica tra le vette spettacolari delle Montagne Rocciose.",
    states: 'Canada',
    days: 7,
  },
  {
    id: 9,
    tour: 'Spiagge Paradisiache in Thailandia',
    description:
      'Rilassati sulle spiagge di sabbia bianca e scopri la cultura affascinante della Thailandia. Alla ricerca di Dario e Daniela spersi nel continente asiatico e prima o poi magari verranno ritrovati a lavorare in una risaia.',
    states: 'Thailandia',
    days: 10,
  },
  {
    id: 10,
    tour: 'Esplorazione della Grande Muraglia',
    description:
      'Cammina lungo la Grande Muraglia e immergiti nella storia millenaria della Cina.',
    states: 'Cina',
    days: 4,
  },
  {
    id: 11,
    tour: 'Viaggio alle Cascate',
    description: 'Esplora le maestose cascate in questa avventura emozionante.',
    states: 'California, Nevada, Hawaii, Utah, Alabama',
    days: 3,
  },
  {
    id: 12,
    tour: 'Tour Culturale a Roma',
    description:
      "Scopri la storia e l'arte della Città Eterna in un tour indimenticabile.",
    states: 'Italia',
    days: 5,
  },
  {
    id: 13,
    tour: 'Avventura nei Monti Rockies',
    description:
      "Un'esperienza di trekking epica tra le vette spettacolari delle Montagne Rocciose.",
    states: 'Canada',
    days: 7,
  },
  {
    id: 14,
    tour: 'Spiagge Paradisiache in Thailandia',
    description:
      'Rilassati sulle spiagge di sabbia bianca e scopri la cultura affascinante della Thailandia. Alla ricerca di Dario e Daniela spersi nel continente asiatico e prima o poi magari verranno ritrovati a lavorare in una risaia.',
    states: 'Thailandia',
    days: 10,
  },
  {
    id: 15,
    tour: 'Esplorazione della Grande Muraglia',
    description:
      'Cammina lungo la Grande Muraglia e immergiti nella storia millenaria della Cina.',
    states: 'Cina',
    days: 4,
  },
];
