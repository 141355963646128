import * as actions from './auth.actions';

import { Dispatch } from '../../store.types';
import { VariableData } from 'core/types/VariableData';
import { SigninDto, SigninResponse } from 'core/mutations/signin';
import { wanderwishApi } from 'core/api';
import { FETCH_ERROR, UNAUTHORIZED_ERROR } from 'core/config/errors';
import { Roles } from 'core/types/Roles';

export const logout = () => async (dispatch: Dispatch) => {
  dispatch(actions.resetAuth());
  dispatch(actions.logout());
};

export const login =
  (variables: VariableData<SigninDto>) => async (dispatch: Dispatch) => {
    dispatch(actions.authStart());
    try {
      const response: SigninResponse = await wanderwishApi.login(variables);
      if (!response?.user?.roles.includes(Roles.TRAVEL_AGENCY)) {
        return dispatch(actions.authError({ error: UNAUTHORIZED_ERROR }));
      }
      return dispatch(actions.authSuccess(response));
    } catch (error) {
      console.error(error);
      return dispatch(actions.authError({ error: FETCH_ERROR }));
    }
  };

export const authErrorReset = () => async (dispatch: Dispatch) => {
  dispatch(actions.authErrorReset());
};
