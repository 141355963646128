import SearchInput from 'components/atoms/search-input';
import MessageListItem from 'components/molecules/message-list-item';
import MessagesChatContainer from 'components/organisms/messages-chat-container';
import { Sidebar } from 'components/organisms/sidebar';
import { Theme, configSelectors } from 'core/store/modules/config';
import { RootState } from 'core/store/store.types';
import { MESSAGES_LIST, Message } from 'mocks/message-list';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

interface OwnProps {}
interface StateProps {
  theme: Theme;
}
interface DispatchProps {}

export type Props = OwnProps & StateProps & DispatchProps;

const Chat: FunctionComponent<Props> = ({ theme }) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [selectedChat, setSelectedChat] = useState<Message | undefined>();
  const [chatInput, setChatInput] = useState('');
  const [showEmoji, setShowEmoji] = useState(false);

  return (
    <div className="w-full h-full flex flex-row">
      <Sidebar activeRef={'/chat'} />
      <div className="p-6 w-full h-full max-h-screen">
        <div className="h-full w-full flex bg-light-bg_component dark:bg-dark-bg_component rounded-2xl shadow-xl dark:shadow-md dark:shadow-dark-bg_component border-2 border-light-active dark:border-white">
          <div className="h-full w-full flex">
            <div
              className="w-4/12 p-8 max-h-full border-r border-light-active dark:border-dark-active"
              style={{ maxHeight: '100%' }}
            >
              <p
                className="text-2xl font-bold text-light-active dark:text-white"
                style={{ height: '5%' }}
              >
                {t('chat_title')}
              </p>
              <div style={{ height: '5%' }}>
                <SearchInput
                  placeholder={t('chat_search_placeholder')}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
              </div>

              <div
                className="max-h-full overflow-auto pt-1 pr-1 mt-2"
                style={{ height: '90%' }}
              >
                {MESSAGES_LIST.map((m, i) => (
                  <MessageListItem
                    key={i}
                    message={m}
                    yesterday={t('common_yesterday')}
                    active={selectedChat?.id?.toString() === m.id.toString()}
                    setSelectedChat={setSelectedChat}
                  />
                ))}
              </div>
            </div>
            <div className="w-8/12">
              {selectedChat && (
                <MessagesChatContainer
                  selectedChat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  text={chatInput}
                  setText={setChatInput}
                  showEmoji={showEmoji}
                  setShowEmoji={setShowEmoji}
                  theme={theme}
                />
              )}
              {!selectedChat && (
                <div className="flex h-full justify-center items-center">
                  <div className="px-1 py-2 border rounded-full border-light-active dark:border-dark-active">
                    <p className="text-light-active dark:text-white">
                      {t('chat_empty_container')}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    theme: configSelectors.getTheme(state),
  }),
  () => ({}),
)(Chat);
