import Button from 'components/atoms/button';
import ToursHeader from 'components/molecules/tours-header';
import { Sidebar } from 'components/organisms/sidebar';
import TourInfo from 'components/organisms/tour-info';
import ToursTable from 'components/organisms/tours-table';
import { TOURS_DATE } from 'mocks/tours-date';
import { TOURS_LIST, Tour } from 'mocks/tours-list';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {}

const Tours: FunctionComponent<Props> = () => {
  const [selectedTour, setSelectedTour] = useState<Tour | undefined>();
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-row">
      <Sidebar activeRef={'/tours'} />
      <div className="p-6 w-full h-full max-h-screen">
        <div className="h-full w-full flex bg-light-bg_component dark:bg-dark-bg_component rounded-2xl shadow-xl dark:shadow-md dark:shadow-dark-bg_component border-2 border-light-active dark:border-white">
          <div className="w-full px-4 flex flex-col">
            <div
              className={`flex flex-col pb-6 ${
                selectedTour
                  ? 'h-1/2 overflow-hidden'
                  : 'h-full overflow-hidden'
              }`}
            >
              <div>
                <ToursHeader
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
              </div>
              <ToursTable
                toursList={TOURS_LIST}
                selectedTour={selectedTour}
                setSelectedTour={setSelectedTour}
              />
            </div>
            {selectedTour && (
              <div className="flex flex-col h-1/2">
                <div>
                  <div className="flex justify-between items-center dark:text-white pb-2">
                    <p className="text-3xl font-medium">{selectedTour?.tour}</p>
                    <Button>{t('tour_new_date')}</Button>
                  </div>
                  <div className="h-0 border border-light-active dark:border-white"></div>
                </div>
                <TourInfo toursDate={TOURS_DATE} selectedTour={selectedTour} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tours;
