import Button from 'components/atoms/button';
import SearchInput from 'components/atoms/search-input';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  searchValue: string;
  setSearchValue: (val: string) => void;
}

const ToursHeader: FunctionComponent<Props> = ({
  searchValue,
  setSearchValue,
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <div className="flex w-full justify-between items-center pt-4">
        <div className="flex justify-between items-center">
          <SearchInput
            placeholder={t('tours_search_placeholder')}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          <Button className="ml-2">{t('common_search')}</Button>
        </div>
        <Button>{t('tours_new_tour')}</Button>
      </div>
    </div>
  );
};

ToursHeader.displayName = 'ToursHeader';

export default ToursHeader;
