import { Message } from 'mocks/message-list';
import { FunctionComponent } from 'react';
import dayjs from 'dayjs';
import { CASUAL_MESSAGES } from 'mocks/chat-messages';
import MessageChatHeader from 'components/molecules/message-chat-header';
import ChatMessage from 'components/atoms/chat-message';
import ChatInput from 'components/molecules/chat-input';
import { Theme } from 'core/store/modules/config';

export interface Props {
  selectedChat: Message;
  setSelectedChat: (message?: Message) => void;
  text: string;
  setText: (text: string) => void;
  showEmoji: boolean;
  setShowEmoji: (value: boolean) => void;
  theme: Theme;
}

const MessagesChatContainer: FunctionComponent<Props> = ({
  selectedChat,
  setSelectedChat,
  text,
  setText,
  showEmoji,
  setShowEmoji,
  theme,
}) => {
  const orderedMessages = CASUAL_MESSAGES.sort((a, b) =>
    dayjs(a.dateSent).diff(dayjs(b.dateSent), 's') > 0 ? -1 : 1,
  );
  return (
    <div className="w-full h-full flex flex-col">
      <MessageChatHeader
        selectedChat={selectedChat}
        setSelectedChat={setSelectedChat}
      />
      <div className="flex px-5 flex-col-reverse max-h-full overflow-auto py-1">
        {orderedMessages.map(m => (
          <ChatMessage key={m.id} message={m} />
        ))}
      </div>
      <div className="p-3">
        <ChatInput
          text={text}
          setText={setText}
          showEmoji={showEmoji}
          setShowEmoji={setShowEmoji}
          theme={theme}
        />
      </div>
    </div>
  );
};

MessagesChatContainer.displayName = 'MessagesChatContainer';

export default MessagesChatContainer;
