import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  published: boolean;
}

const StatusTag: FunctionComponent<Props> = ({ published }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`rounded-full w-fit px-4 py-1 text-sm font-normal  ${
        published
          ? 'bg-bg_success text-success'
          : 'bg-bg_error opacity-2 text-error'
      }`}
    >
      {published ? t('common_published') : t('common_unpublished')}
    </div>
  );
};

StatusTag.displayName = 'StatusTag';

export default StatusTag;
