import { Middleware } from 'redux';

import * as actions from './auth.actions';
import {
  clearRefreshToken,
  clearToken,
  clearTokenId,
  getRefreshToken,
  getToken,
  getTokenId,
  setRefreshToken,
  setToken,
  setTokenId,
} from 'core/storage';
import { INIT_ACTION_TYPE } from 'core/store/store.const';

export const onInitRestoreToken: Middleware =
  ({ dispatch }) =>
  next =>
  action => {
    const nextAction = next(action);
    if (action.type === INIT_ACTION_TYPE) {
      const accessToken = getToken() ?? undefined;
      const refreshToken = getRefreshToken() ?? undefined;
      const tokenId = getTokenId() ?? undefined;
      dispatch(actions.restoreTokens({ accessToken, refreshToken, tokenId }));
    }
    return nextAction;
  };

export const onChangePersistToken: Middleware = () => next => action => {
  if (actions.authSuccess.match(action)) {
    const { accessToken, refreshToken, tokenId } = action.payload;
    setToken(accessToken);
    setTokenId(tokenId);
    setRefreshToken(refreshToken);
  }
  return next(action);
};

export const onAuthErrorClearToken: Middleware = () => next => action => {
  if (actions.authError.match(action)) {
    clearToken();
    clearRefreshToken();
    clearTokenId();
  }
  return next(action);
};

export const onLogout: Middleware = () => next => action => {
  if (actions.logout.match(action)) {
    clearToken();
    clearRefreshToken();
    clearTokenId();
  }
  return next(action);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  onInitRestoreToken,
  onChangePersistToken,
  onAuthErrorClearToken,
  onLogout,
];
