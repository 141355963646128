import { Theme, configSelectors } from 'core/store/modules/config';
import { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authSelectors } from './core/store/modules/auth';
import { RootState } from './core/store/store.types';

interface OwnProps {
  children: ReactElement;
}
interface StateProps {
  token?: string;
  theme: Theme;
}
interface DispatchProps {}

export type Props = OwnProps & StateProps & DispatchProps;

const App: FunctionComponent<Props> = ({ token, children, theme }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    document.body.classList.remove('light', 'dark');
    document.body.classList.add(theme);
  }, [theme]);

  return (
    <div className="flex h-screen min-h-screen max-h-screen overflow-hidden font-sans antialiased bg-light-bg dark:bg-dark-bg">
      <main className="flex-grow">{children}</main>
    </div>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    token: authSelectors.getToken(state),
    theme: configSelectors.getTheme(state),
  }),
  () => ({}),
)(App);
