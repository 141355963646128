import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { State } from './auth.types';
import * as actions from './auth.actions';

export const initialState: Readonly<State> = {
  token: undefined,
  auth: {
    loading: false,
  },
};

export default reducerWithInitialState(initialState)
  .case(actions.resetAuth, () => ({ ...initialState }))
  .case(
    actions.restoreTokens,
    (state, { accessToken, refreshToken, tokenId }) => ({
      ...state,
      auth: { ...state.auth, loading: false },
      token: accessToken,
      refreshToken,
      tokenId,
    }),
  )
  .case(actions.logout, state => ({
    ...state,
    token: undefined,
    refreshToken: undefined,
    tokenId: undefined,
  }))
  .case(actions.authStart, state => ({
    ...state,
    auth: {
      loading: true,
      error: undefined,
    },
  }))
  .case(
    actions.authSuccess,
    (state, { accessToken, refreshToken, tokenId }) => ({
      ...state,
      token: accessToken,
      refreshToken,
      tokenId,
      auth: {
        ...state.auth,
        loading: false,
      },
    }),
  )
  .case(actions.authError, (state, { error }) => ({
    ...state,
    auth: {
      loading: false,
      error,
    },
  }))
  .case(actions.authErrorReset, state => ({
    ...state,
    auth: { ...state.auth, error: initialState.auth.error },
  }))
  .build();
