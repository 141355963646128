import React, { ChangeEvent, FunctionComponent } from 'react';

interface Props {
  id: string;
  label: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  endContent?: JSX.Element;
  type?: string;
}

const Input: FunctionComponent<Props> = ({
  id,
  label,
  value,
  onChange,
  placeholder,
  endContent,
  type = 'text',
}) => {
  return (
    <div className="group flex flex-col w-full bg-light-bg_input dark:bg-dark-bg_input rounded-xl">
      <div className="relative w-full inline-flex tap-highlight-transparent shadow-sm px-3 bg-default-100 data-[hover=true]:bg-default-200 group-data-[focus=true]:bg-default-100 min-h-unit-10 rounded-medium flex-col items-start justify-center gap-0 transition-background motion-reduce:transition-none !duration-150 outline-none group-data-[focus-visible=true]:z-10 group-data-[focus-visible=true]:ring-2 group-data-[focus-visible=true]:ring-focus group-data-[focus-visible=true]:ring-offset-2 group-data-[focus-visible=true]:ring-offset-background h-14 py-2">
        <label
          className="block font-medium text-foreground-600 dark:text-white text-tiny cursor-text will-change-auto origin-top-left transition-all !duration-200 !ease-out motion-reduce:transition-none"
          htmlFor={id}
        >
          {label}
        </label>
        <div className="w-full flex">
          <input
            className="appareance-none w-full h-full font-normal !bg-light-bg_input dark:!bg-dark-bg_input outline-none placeholder:text-foreground-500 text-small dark:text-white autofill:shadow-inner_light dark:autofill:shadow-inner_dark dark:text-fill-white"
            type={type}
            name={id}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
          {endContent}
        </div>
      </div>
    </div>
  );
};

export default Input;
