import { Theme } from 'core/store/modules/config';
import { MoonFilledIcon } from 'icons/moon-filled-icon';
import { SunFilledIcon } from 'icons/sun-filled-icon';
import { useState } from 'react';

interface Props {
  theme: Theme;
  handleChangeTheme: (theme: Theme) => void;
}
function ThemeSwitch({ theme, handleChangeTheme }: Props) {
  const [enabled, setEnabled] = useState(theme === 'light');

  const handleThemeChange = (enabled: boolean) => {
    handleChangeTheme(enabled ? Theme.light : Theme.dark);
    setEnabled(enabled);
  };

  return (
    <>
      {enabled && <MoonFilledIcon onClick={() => handleThemeChange(false)} />}
      {!enabled && (
        <SunFilledIcon
          className="text-white"
          onClick={() => handleThemeChange(true)}
        />
      )}
    </>
  );
}

export default ThemeSwitch;
