import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { State, Theme } from './config.types';
import * as actions from './config.actions';

export const initialState: Readonly<State> = {
  collapsed: true,
  theme: Theme.light,
};

export default reducerWithInitialState(initialState)
  .case(actions.resetConfig, () => ({ ...initialState }))
  .case(actions.setCollapsed, state => ({
    ...state,
    collapsed: !state.collapsed,
  }))
  .case(actions.setTheme, (state, theme) => ({
    ...state,
    theme,
  }))
  .build();
