import { Sidebar } from 'components/organisms/sidebar';
import React from 'react';

const Requests = () => {
  return (
    <div className="w-full h-full flex flex-row">
      <Sidebar activeRef={'/reservation-requests'} />

      <div className="p-6 w-full h-full max-h-screen">
        <div className="h-full w-full flex bg-light-bg_component dark:bg-dark-bg_component rounded-2xl shadow-xl dark:shadow-md dark:shadow-dark-bg_component border-2 border-light-active dark:border-white">
          <p className="dark:text-white">Requests</p>
        </div>
      </div>
    </div>
  );
};

export default Requests;
