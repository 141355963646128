import { FunctionComponent } from 'react';
import { Message } from 'mocks/message-list';
import { getDateString } from 'core/utils/date';
import { getInitials } from 'core/utils/get-initials';
import UnreadMessagesBadge from 'components/atoms/unread-message-badge';

export interface Props {
  message: Message;
  setSelectedChat: (message: Message) => void;
  yesterday: string;
  active: boolean;
}

const MessageListItem: FunctionComponent<Props> = ({
  message,
  setSelectedChat,
  yesterday,
  active,
}) => {
  const getDateStringToShow = () => {
    const string = getDateString(message.time);
    if (string === 'yesterday') {
      return yesterday;
    }
    return string;
  };

  return (
    <div onClick={() => setSelectedChat(message)}>
      <div
        className={`flex py-2 justify-between pr-2 pl-1 ${
          active
            ? 'border border-l-4 border-light-active dark:border-dark-active shadow-2xl rounded-e-2xl'
            : ''
        }`}
      >
        <div className="flex items-center">
          <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
            <span className="font-medium text-light-active dark:text-white">
              {getInitials(message.name)}
            </span>
          </div>
        </div>
        <div className="px-2 text-light-active dark:text-white w-4/6">
          <p className="text-base font-bold">{message.title}</p>
          <p className="text-sm font-bold">{message.name}</p>
          <p className="line-clamp-2 font-normal text-sm">{message.message}</p>
        </div>
        <div className="flex flex-col items-end justify-between w-1/6 text-light-active dark:text-white">
          <p className="font-normal text-sm">{getDateStringToShow()}</p>
          <div>
            <UnreadMessagesBadge number={message.toBeRead} />
          </div>
        </div>
      </div>
    </div>
  );
};

MessageListItem.displayName = 'MessageListItem';

export default MessageListItem;
