import { TOKEN_ID_STORAGE_KEY } from './storage.tokenId.const';

let cachedToken: string | null;

export const getTokenId = () => {
  if (cachedToken) {
    return cachedToken;
  }
  try {
    cachedToken = localStorage.getItem(TOKEN_ID_STORAGE_KEY);
    return cachedToken;
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export const setTokenId = (newToken: string) => {
  try {
    localStorage.setItem(TOKEN_ID_STORAGE_KEY, newToken);
    cachedToken = newToken;
  } catch (e) {
    console.warn(e);
  }
};

export const clearTokenId = () => {
  try {
    localStorage.removeItem(TOKEN_ID_STORAGE_KEY);
    cachedToken = null;
  } catch (e) {
    console.warn(e);
  }
};
