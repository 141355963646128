import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './store.reducer';
import storeMiddlewares from './store.middleware';

import { INIT_ACTION_TYPE } from './store.const';

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, ...storeMiddlewares)),
);

store.dispatch({
  type: INIT_ACTION_TYPE,
  payload: undefined,
});

export default store;
