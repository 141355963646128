import { SigninResponse } from 'core/mutations/signin';
import { actionCreatorFactory } from 'typescript-fsa';

const actionCreator = actionCreatorFactory('auth');

export const resetAuth = actionCreator<void>('RESET');

export const restoreTokens = actionCreator<{
  accessToken?: string;
  refreshToken?: string;
  tokenId?: string;
}>('TOKEN_RESTORE');

export const logout = actionCreator<void>('LOGOUT');

export const authStart = actionCreator<void>('AUTH_START');
export const authSuccess = actionCreator<SigninResponse>('AUTH_SUCCESS');
export const authError = actionCreator<{ error: string }>('AUTH_ERROR');
export const authErrorReset = actionCreator<void>('AUTH_ERROR_RESET');
