export type TourDatesType = {
  id: number;
  endDate: Date;
  departureDate: Date;
  pendingRequests: number;
  completedRequest: number;
  published: boolean;
};

export const TOURS_DATE = [
  {
    id: 1,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: false,
  },
  {
    id: 2,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: true,
  },
  {
    id: 3,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: true,
  },
  {
    id: 4,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: true,
  },
  {
    id: 5,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: true,
  },
  {
    id: 6,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: true,
  },
  {
    id: 7,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: true,
  },
  {
    id: 8,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: false,
  },
  {
    id: 9,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: true,
  },
  {
    id: 10,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: true,
  },
  {
    id: 11,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: true,
  },
  {
    id: 12,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: true,
  },
  {
    id: 13,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: true,
  },
  {
    id: 14,
    endDate: new Date(),
    departureDate: new Date(),
    pendingRequests: 10,
    completedRequest: 3,
    published: true,
  },
];
