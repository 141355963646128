import { RootState } from '../../store.types';
import { State } from './auth.types';

export const getCurrentState = (state: RootState): State => state.auth;

export const getToken = (state: RootState) => getCurrentState(state).token;

export const getLoginLoading = (state: RootState) =>
  getCurrentState(state).auth.loading;
export const getLoginError = (state: RootState) =>
  getCurrentState(state).auth.error;
