import authReducer from './modules/auth/auth.reducer';
import configReducer from './modules/config/config.reducer';
import { Action, RootState } from './store.types';

import { combineReducers } from 'redux';

export default combineReducers<RootState, Action>({
  auth: authReducer,
  config: configReducer,
});
