import { FunctionComponent } from 'react';

export interface Props {
  number: number;
}

const UnreadMessagesBadge: FunctionComponent<Props> = ({ number }) => {
  return (
    <div className="flex justify-end">
      {number !== 0 && (
        <div className="w-6 h-6 bg-error rounded-full flex items-center justify-center">
          <p className="text-xs font-black text-white">{number}</p>
        </div>
      )}
    </div>
  );
};

export default UnreadMessagesBadge;
