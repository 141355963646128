import { getInitials } from 'core/utils/get-initials';
import { Message } from 'mocks/message-list';
import { FunctionComponent } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';

export interface Props {
  selectedChat: Message;
  setSelectedChat: (message?: Message) => void;
}

const MessageChatHeader: FunctionComponent<Props> = ({
  selectedChat,
  setSelectedChat,
}) => (
  <div className="flex justify-between border-b border-light-active dark:border-dark-active p-4">
    <div className="flex items-center">
      <div className="flex items-center">
        <div className="relative inline-flex items-center justify-center w-14 h-14 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
          <span className="font-medium text-light-active dark:text-white">
            {getInitials(selectedChat.name)}
          </span>
        </div>
      </div>
      <div className="flex flex-col pl-4">
        <p className="text-light-active dark:text-white text-xl font-bold">
          {selectedChat.title}
        </p>
        <p className="text-light-active dark:text-white text-base font-bold">
          {selectedChat.name}
        </p>
      </div>
    </div>
    <div
      className="flex items-center cursor-pointer"
      onClick={() => setSelectedChat()}
    >
      <IoIosCloseCircleOutline className="w-16 h-16 text-light-active dark:text-white" />
    </div>
  </div>
);

MessageChatHeader.displayName = 'MessageChatHeader';

export default MessageChatHeader;
