import { AxiosResponse } from 'axios';

export const handleErrorGraphQL = (config: AxiosResponse) => {
  if (config.data.errors) {
    // eslint-disable-next-line no-throw-literal
    throw { ...config.data };
  }

  return config;
};
