import { Middleware } from 'redux';

import * as actions from './config.actions';
import { INIT_ACTION_TYPE } from 'core/store/store.const';
import { getTheme } from 'core/storage';
import { Theme } from './config.types';

export const onInitRestoreTheme: Middleware =
  ({ dispatch }) =>
  next =>
  action => {
    const nextAction = next(action);
    if (action.type === INIT_ACTION_TYPE) {
      const theme = getTheme();
      if (theme) {
        dispatch(
          actions.setTheme(theme === 'light' ? Theme.light : Theme.dark),
        );
      }
    }
    return nextAction;
  };

// eslint-disable-next-line import/no-anonymous-default-export
export default [onInitRestoreTheme];
