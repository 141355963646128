import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FunctionComponent,
} from 'react';

export interface Props {
  className?: string;
}

const Button: FunctionComponent<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ className, children, ...otherProps }) => (
  <button
    className={`min-w-[5rem] text-white bg-light-active dark:bg-dark-active border-2 border-light-active dark:border-white rounded-xl p-2 text-md ${className}`}
    {...otherProps}
  >
    {children}
  </button>
);

Button.displayName = 'Button';

export default Button;
