import { REFRESH_TOKEN_STORAGE_KEY } from './storage.refreshToken.const';

let cachedToken: string | null;

export const getRefreshToken = () => {
  if (cachedToken) {
    return cachedToken;
  }
  try {
    cachedToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
    return cachedToken;
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export const setRefreshToken = (newToken: string) => {
  try {
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, newToken);
    cachedToken = newToken;
  } catch (e) {
    console.warn(e);
  }
};

export const clearRefreshToken = () => {
  try {
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    cachedToken = null;
  } catch (e) {
    console.warn(e);
  }
};
