/* eslint-disable import/no-anonymous-default-export */

import { SIGNIN, SigninDto } from 'core/mutations/signin';
import { ApiFactoryClients } from '../api.types';
import { print } from 'graphql';
import gql from 'graphql-tag';
import { VariableData } from 'core/types/VariableData';

export default ({
  wanderwishHttpClient,
}: ApiFactoryClients<'wanderwishHttpClient'>) => ({
  login: (variables: VariableData<SigninDto>) =>
    wanderwishHttpClient
      .post('/', { query: print(gql(SIGNIN)), variables })
      .then(({ data }) => data.data.signIn),
});
