import StatusTag from 'components/atoms/status-tag';
import dayjs from 'dayjs';
import { TourDatesType } from 'mocks/tours-date';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSettingsOutline } from 'react-icons/io5';

export interface Props {
  toursDate: TourDatesType[];
}

const HEADERS_TAB = [
  {
    id: 1,
    label: 'common_departure_date',
  },
  {
    id: 2,
    label: 'common_end_date',
  },
  {
    id: 3,
    label: 'day_table_pending_request',
  },
  {
    id: 4,
    label: 'day_table_confirmed_request',
  },
  {
    id: 5,
    label: 'common_status',
  },
  {
    id: 6,
    label: '',
  },
];

const TourTableDate: FunctionComponent<Props> = ({ toursDate }) => {
  const { t } = useTranslation();
  return (
    <div className="w-2/3 h-full overflow-y-auto">
      <table className="w-full max-h-full">
        <thead className="sticky top-0">
          <tr className="bg-light-active dark:bg-dark-active text-white">
            {HEADERS_TAB.map(tab => (
              <th
                className="h-16 px-2 text-left font-semibold text-xs"
                key={tab.id}
              >
                {t(tab.label)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="dark:text-white">
          <tr className="h-2" />

          {toursDate.map(t => (
            <tr className="h-10 text-sm font-normal" key={t.id}>
              <td className="px-2 pb-2">
                {dayjs(t.departureDate).format('DD-MM-YYYY')}
              </td>
              <td className="px-2 pb-2">
                {dayjs(t.endDate).format('DD-MM-YYYY')}
              </td>
              <td className="px-2 pb-2">{t.pendingRequests}</td>
              <td className="px-2 pb-2">{t.completedRequest}</td>
              <td className="px-2 pb-2">
                <StatusTag published={t.published} />
              </td>
              <td className="px-2 pb-2">
                <button
                  onClick={(evt: React.MouseEvent) => {
                    evt.stopPropagation();
                    console.log('info Data');
                  }}
                >
                  <IoSettingsOutline />
                </button>
              </td>
            </tr>
          ))}
          <tr className="h-4" />
        </tbody>
      </table>
    </div>
  );
};

TourTableDate.displayName = 'TourTableDate';

export default TourTableDate;
