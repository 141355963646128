export const SIGNIN = `
mutation signIn($data: SigninDto!) {
  signIn(data: $data) {
    accessToken
    refreshToken
    tokenId
    user {
      id
      email
      roles
    }
  }
}
`;
