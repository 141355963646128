export const MESSAGES_LIST = [
  {
    id: 1,
    title: 'Title 1',
    name: 'Name 1',
    message: 'This is a short message.',
    toBeRead: 0,
    time: new Date('2023-12-05T10:30:00'),
  },
  {
    id: 2,
    title: 'Title 2',
    name: 'Name 2',
    message:
      'This is a somewhat longer message that provides more information.',
    toBeRead: 1,
    time: new Date('2023-12-04T14:45:00'),
  },
  {
    id: 3,
    title: 'Title 3',
    name: 'Name 3',
    message:
      "Here's an even longer message with additional details and content to read.",
    toBeRead: 2,
    time: new Date('2023-09-29T16:15:00'),
  },
  {
    id: 4,
    title: 'Title 4',
    name: 'Name 4',
    message:
      'This is a very long message that contains a lot of information, and it continues on and on and on...',
    toBeRead: 3,
    time: new Date('2023-09-28T08:00:00'),
  },
  {
    id: 5,
    title: 'Title 5',
    name: 'Name 5',
    message: 'Short message.',
    toBeRead: 4,
    time: new Date('2023-09-27T12:30:00'),
  },
  {
    id: 6,
    title: 'Title 6',
    name: 'Name 6',
    message: 'This is a medium-length message with some details.',
    toBeRead: 5,
    time: new Date('2023-09-26T20:15:00'),
  },
  {
    id: 7,
    title: 'Title 7',
    name: 'Name 7',
    message: 'A brief message to read quickly.',
    toBeRead: 6,
    time: new Date('2023-09-25T09:45:00'),
  },
  {
    id: 8,
    title: 'Title 8',
    name: 'Name 8',
    message:
      'This message has a moderate length and contains important information.',
    toBeRead: 7,
    time: new Date('2023-09-24T15:20:00'),
  },
  {
    id: 9,
    title: 'Title 9',
    name: 'Name 9',
    message: 'A very brief message.',
    toBeRead: 8,
    time: new Date('2023-09-23T13:10:00'),
  },
  {
    id: 10,
    title: 'Title 10',
    name: 'Name 10',
    message:
      'This is a long message that covers a lot of information and content to read.',
    toBeRead: 9,
    time: new Date('2023-09-22T11:25:00'),
  },
  {
    id: 11,
    title: 'Title 11',
    name: 'Name 11',
    message: 'A brief message.',
    toBeRead: 10,
    time: new Date('2023-09-21T17:40:00'),
  },
  {
    id: 12,
    title: 'Title 12',
    name: 'Name 12',
    message:
      'This message is of moderate length and conveys essential details.',
    toBeRead: 11,
    time: new Date('2023-09-20T09:20:00'),
  },
  {
    id: 13,
    title: 'Title 13',
    name: 'Name 13',
    message: 'A very short message.',
    toBeRead: 12,
    time: new Date('2023-09-19T16:50:00'),
  },
  {
    id: 14,
    title: 'Title 14',
    name: 'Name 14',
    message: 'This is a lengthy message with lots of information and content.',
    toBeRead: 13,
    time: new Date('2023-09-18T14:15:00'),
  },
  {
    id: 15,
    title: 'Title 15',
    name: 'Name 15',
    message: 'This is a super long message that goes on and on...',
    toBeRead: 14,
    time: new Date('2023-09-17T11:30:00'),
  },
  {
    id: 16,
    title: 'Title 16',
    name: 'Name 16',
    message: 'A concise message to read quickly.',
    toBeRead: 15,
    time: new Date('2023-09-16T18:10:00'),
  },
  {
    id: 17,
    title: 'Title 17',
    name: 'Name 17',
    message:
      'This message has a reasonable length and contains important details.',
    toBeRead: 16,
    time: new Date('2023-09-15T19:25:00'),
  },
  {
    id: 18,
    title: 'Title 18',
    name: 'Name 18',
    message: 'A very short message.',
    toBeRead: 17,
    time: new Date('2023-09-14T22:40:00'),
  },
  {
    id: 19,
    title: 'Title 19',
    name: 'Name 19',
    message:
      'This is a long message that provides a lot of information and content to read.',
    toBeRead: 18,
    time: new Date('2023-09-13T07:55:00'),
  },
  {
    id: 20,
    title: 'Title 20',
    name: 'Name 20',
    message: 'A brief message to convey important information.',
    toBeRead: 19,
    time: new Date('2023-09-12T13:20:00'),
  },
  {
    id: 21,
    title: 'Title 21',
    name: 'Name 21',
    message: 'A moderately long message with additional details and content.',
    toBeRead: 20,
    time: new Date('2023-09-11T10:45:00'),
  },
  {
    id: 22,
    title: 'Title 22',
    name: 'Name 22',
    message:
      'This message has a reasonable length and conveys important information.',
    toBeRead: 21,
    time: new Date('2023-09-10T15:55:00'),
  },
  {
    id: 23,
    title: 'Title 23',
    name: 'Name 23',
    message: 'A very short message.',
    toBeRead: 22,
    time: new Date('2023-09-09T12:35:00'),
  },
  {
    id: 24,
    title: 'Title 24',
    name: 'Name 24',
    message:
      'This is a long message with detailed information and content to read.',
    toBeRead: 23,
    time: new Date('2023-09-08T20:05:00'),
  },
  {
    id: 25,
    title: 'Title 25',
    name: 'Name 25',
    message: 'A brief message.',
    toBeRead: 24,
    time: new Date('2023-09-07T17:30:00'),
  },
];

export type Message = {
  id: number;
  title: string;
  name: string;
  message: string;
  toBeRead: number;
  time: Date;
};
